.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 60px;
}

#main-content {
  flex-grow: 1;
}
